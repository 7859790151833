import React from 'react';

export const SIKKA_FAQS = [
  {
    key: 'how-can-i-sign-up-on-InCash-app',
    hi: {
      title: <h4>मैं इनकॅश ऐप पर कैसे साइनअप कर सकता हूं?</h4>,
      desc: (
        <div>
          यह आसान है, साइनअप पर जाएं और अपना फोन नंबर दर्ज करें। आपको ओटीपी के
          साथ एक एसएमएस प्राप्त होगा। इनकॅश ऐप पर ओटीपी दर्ज करें। आप पुरस्कार
          अर्जित करने के लिए तैयार हैं।ऑफ़र का आनंद लें।
        </div>
      ),
    },
    en: {
      title: <h4>How can I sign up on InCash App?</h4>,
      desc: (
        <div>
          It’s simple, go to the Signup and enter your phone number. You will
          receive a SMS with OTP. Enter the OTP on InCash App. You are ready to
          earn the rewards and enjoy the offers.
        </div>
      ),
    },
    mr: {
      title: <h4>मी InCash अॅपवर कसे साइन अप करू शकतो?</h4>,
      desc: (
        <div>
          हे सोपे आहे, साइनअप वर जा आणि तुमचा फोन नंबर प्रविष्ट करा. तुम्हाला
          OTP सह एक SMS प्राप्त होईल. InCash अॅपवर OTP टाका. तुम्ही रिवॉर्ड
          मिळवण्यासाठी आणि ऑफरचा आनंद घेण्यासाठी तयार आहात.
        </div>
      ),
    },
    gu: {
      title: <h4>હું InCash એપ પર કેવી રીતે સાઇન અપ કરી શકું?</h4>,
      desc: (
        <div>
          તે સરળ છે, સાઇનઅપ પર જાઓ અને તમારો ફોન નંબર દાખલ કરો. તમને OTP સાથે એક
          SMS પ્રાપ્ત થશે. InCash એપ પર OTP દાખલ કરો. તમે પુરસ્કારો મેળવવા અને
          ઑફર્સનો આનંદ લેવા માટે તૈયાર છો
        </div>
      ),
    },
  },
  {
    key: 'what-are-the-methods-to-earn-rewards-on-InCash-app',
    hi: {
      title: <h4>इनकॅश ऐप पर रिवॉर्ड कमाने के क्या तरीके हैं?</h4>,
      desc: (
        <div>
          <p>
            इनकॅश पर पुरस्कार अर्जित करने के लिए आप निम्नलिखित कार्य कर सकते
            हैं:
          </p>
          <ul>
            <li>ऐप पर ऑफर का लाभ उठाएं।</li>
            <li>
              अपने मित्र को आमंत्रित करें और आप दोनों पुरस्कार अर्जित करें।
            </li>
            <li>दैनिक चेक-इन करें और अपना दैनिक चेकइन अर्जित करें।</li>
          </ul>
        </div>
      ),
    },
    en: {
      title: <h4>What are the methods to earn rewards on InCash App?</h4>,
      desc: (
        <div>
          <p>You can do the following Tasks for earning rewards on InCash:</p>
          <ul>
            <li>Avail the offers on the App.</li>
            <li>Refer to your Friends and you both earn rewards.</li>
            <li>Daily Check in and claim your daily check-in reward.</li>
          </ul>
        </div>
      ),
    },
    mr: {
      title: <h4>इनकॅश अॅपवर रिवॉर्ड मिळविण्याच्या कोणत्या पद्धती आहेत?</h4>,
      desc: (
        <div>
          <p>InCash वर बक्षिसे मिळवण्यासाठी तुम्ही खालील कार्ये करू शकता:</p>
          <ul>
            <li>अॅपवर ऑफरचा लाभ घ्या.</li>
            <li>
              तुमच्या मित्रांचा संदर्भ घ्या आणि तुम्ही दोघे बक्षिसे मिळवाल.
            </li>
            <li>
              दररोज चेक इन करा आणि आपल्या दैनिक चेक-इन रिवॉर्डवर दावा करा.
            </li>
          </ul>
        </div>
      ),
    },
    gu: {
      title: <h4>InCash એપ પર પુરસ્કારો મેળવવાની કઈ રીતો છે?</h4>,
      desc: (
        <div>
          <p>તમે InCash પર પુરસ્કારો મેળવવા માટે નીચેના કાર્યો કરી શકો છો:</p>
          <ul>
            <li>એપ પર ઓફર્સનો લાભ લો.</li>
            <li>તમારા મિત્રોનો સંદર્ભ લો અને તમે બંને પુરસ્કારો મેળવો.</li>
            <li>
              દૈનિક ચેક ઇન કરો અને તમારા દૈનિક ચેક-ઇન પુરસ્કારનો દાવો કરો.
            </li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-avail-an-offer',
    hi: {
      title: <h4>मैं ऑफ़र का लाभ कैसे उठा सकता/सकती हूं?</h4>,
      desc: (
        <div>
          ऑफ़र पर क्लिक करें और आप ऑफ़र विवरण तक पहुँच जाते हैं। सभी निर्देशों
          को ध्यान से पढ़ें और सभी निर्देशों को एक-एक करके करें। जैसे ही हमें
          विज्ञापनदाताओं की ओर से पुष्टि मिलेगी, इनकॅश आपके खाते में क्रेडिट कर
          दिया जाएगा।
        </div>
      ),
    },
    en: {
      title: <h4>How can I avail an offer?</h4>,
      desc: (
        <div>
          Click on the offer and you reach the Offer Details. Read all the steps
          carefully and perform all the steps one by one. InCashes will be
          credited to your account as soon as we get confirmation from the
          Advertisers side.
        </div>
      ),
    },
    mr: {
      title: <h4>मी ऑफरचा लाभ कसा घेऊ शकतो?</h4>,
      desc: (
        <div>
          ऑफरवर क्लिक करा आणि तुम्ही ऑफर तपशीलांपर्यंत पोहोचाल. सर्व पायऱ्या
          काळजीपूर्वक वाचा आणि सर्व पायऱ्या एकामागून एक करा. आम्हाला
          जाहिरातदारांकडून पुष्टी मिळताच तुमच्या खात्यात InCashes जमा केले
          जातील.
        </div>
      ),
    },
    gu: {
      title: <h4>હું ઑફર કેવી રીતે મેળવી શકું?</h4>,
      desc: (
        <div>
          ઑફર પર ક્લિક કરો અને તમે ઑફરની વિગતો સુધી પહોંચો. બધા પગલાંઓ
          કાળજીપૂર્વક વાંચો અને એક પછી એક બધા પગલાંઓ કરો. જેમ અમને જાહેરાતકર્તાઓ
          તરફથી પુષ્ટિ મળશે કે તરત જ તમારા ખાતામાં InCashes જમા થઈ જશે.
        </div>
      ),
    },
  },
  {
    key: 'when-will-the-InCashes-be-added-to-my-wallet',
    hi: {
      title: <h4>इनकॅश मेरे वॉलेट में कब आएंगे?</h4>,
      desc: (
        <div>
          जैसे ही हमें विज्ञापनदाता की ओर से इस बात की पुष्टि मिलती है कि आपने
          ऑफ़र में उल्लिखित सभी चरणों को पूरा कर लिया है, इनकॅश आपके खाते में
          क्रेडिट कर दिया जाएगा।
        </div>
      ),
    },
    en: {
      title: <h4>When will the InCashes be added to my Wallet?</h4>,
      desc: (
        <div>
          InCashes will be credited to your account as soon as we get
          confirmation from the Advertiser side that you have completed all the
          steps that were mentioned in the offer.
        </div>
      ),
    },
    mr: {
      title: <h4>माझ्या वॉलेटमध्ये InCashes कधी जोडले जातील?</h4>,
      desc: (
        <div>
          ऑफरमध्ये नमूद केलेल्या सर्व पायऱ्या तुम्ही पूर्ण केल्या असल्याची
          पुष्टी आम्हाला जाहिरातदाराकडून मिळताच तुमच्या खात्यात InCashes जमा
          केले जातील.
        </div>
      ),
    },
    gu: {
      title: <h4>મારા વૉલેટમાં InCashes ક્યારે ઉમેરવામાં આવશે?</h4>,
      desc: (
        <div>
          જેમ જેમ અમને જાહેરાતકર્તા તરફથી પુષ્ટિ મળશે કે તમે ઑફરમાં ઉલ્લેખિત
          તમામ પગલાં પૂર્ણ કરી લીધા છે કે તરત જ તમારા એકાઉન્ટમાં InCashes જમા થઈ
          જશે.
        </div>
      ),
    },
  },
  {
    key: 'how-many-InCashes-make-one-1-rupee',
    hi: {
      title: <h4>1 रुपये में कितने इनकॅश बनते हैं?</h4>,
      desc: <div>रूपांतरण के लिए १० इनकॅश = १ रुपया</div>,
    },
    en: {
      title: <h4>How many InCashes make one 1 rupee?</h4>,
      desc: <div>For conversion 10 InCashes = 1 rupee</div>,
    },
    mr: {
      title: <h4>किती InCashes एक रुपया बनवतात?</h4>,
      desc: <div>रूपांतरणासाठी 10 InCashes = 1 रुपया</div>,
    },
    gu: {
      title: <h4>કેટલા InCashes એક 1 રૂપિયો બનાવે છે?</h4>,
      desc: <div>રૂપાંતરણ માટે 10 InCashes = 1 રૂપિયા</div>,
    },
  },
  {
    key: 'what-are-the-ongoing-offers',
    hi: {
      title: <h4>चल रहे ऑफर क्या हैं?</h4>,
      desc: (
        <div>
          यदि आपने ऑफ़र के कुछ चरणों को पूरा कर लिया है और सभी चरणों को पूरा
          नहीं किया है या यदि हम विज्ञापनदाताओं से आपके द्वारा पूरे किए गए चरणों
          की पुष्टि की प्रतीक्षा कर रहे हैं तो एक ऑफ़र चल रहे ऑफ़र में आता है।
        </div>
      ),
    },
    en: {
      title: <h4>What are the ongoing offers?</h4>,
      desc: (
        <div>
          An offer is an ongoing offer if you have completed a few steps of the
          offer and not all the steps or if we are waiting for a confirmation of
          the steps completed by you from advertisers.
        </div>
      ),
    },
    mr: {
      title: <h4>चालू असलेल्या ऑफर काय आहेत?</h4>,
      desc: (
        <div>
          जर तुम्ही ऑफरचे काही टप्पे पूर्ण केले असतील आणि सर्व पायऱ्या पूर्ण
          केल्या नसतील किंवा आम्ही जाहिरातदारांकडून तुमच्याद्वारे पूर्ण केलेल्या
          चरणांच्या पुष्टीकरणाची वाट पाहत असाल तर ऑफर ही एक चालू ऑफर आहे.
        </div>
      ),
    },
    gu: {
      title: <h4>ચાલુ ઓફરો શું છે?</h4>,
      desc: (
        <div>
          ઑફર એ ચાલુ ઑફર છે જો તમે ઑફરના થોડાં પગલાં પૂર્ણ કર્યા હોય અને બધાં જ
          પગલાં ન ભર્યા હોય અથવા જો અમે જાહેરાતકર્તાઓ તરફથી તમારા દ્વારા પૂર્ણ
          કરેલા પગલાંની પુષ્ટિની રાહ જોઈ રહ્યાં હોય.
        </div>
      ),
    },
  },
  {
    key: 'what-is-an-additional-reward-how-can-i-claim-it',
    hi: {
      title: <h4>एक अतिरिक्त रिवॉर्ड क्या है? मैं इसे कैसे कमा सकता हूं?</h4>,
      desc: (
        <div>
          एक बार जब आप किसी ऑफ़र के लिए दिए गए चरणों को पूरा कर लेते हैं, तो आप
          अतिरिक्त रिवॉर्ड प्राप्त करने के योग्य हो जाते हैं। आप बताए गए दिनों
          के लिए ऑफ़र से ऐप को डाउनलोड करके रख कर इसका लाभ उठा सकते हैं। आप
          रोजाना ऐप खोलकर ऐप को रखने के लिए दैनिक रिवॉर्ड प्राप्त कर सकते हैं और
          अंतिम दिन आप शेष इनाम अर्जित कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>What is an additional reward? How can I claim it?</h4>,
      desc: (
        <div>
          Once you complete the Steps given for an offer, you are eligible to
          avail the additional reward. You can avail it by keeping the app
          downloaded from the offer for the mentioned number of days. You can
          get daily rewards for keeping the app by opening the app daily and on
          the last day you can claim the remaining reward.
        </div>
      ),
    },
    mr: {
      title: <h4>अतिरिक्त बक्षीस काय आहे? मी त्यावर दावा कसा करू शकतो?</h4>,
      desc: (
        <div>
          एकदा तुम्ही ऑफरसाठी दिलेल्या पायऱ्या पूर्ण केल्यावर, तुम्ही अतिरिक्त
          रिवॉर्डचा लाभ घेण्यास पात्र आहात. ऑफरमधून डाउनलोड केलेले अॅप नमूद
          केलेल्या दिवसांसाठी ठेवून तुम्ही त्याचा लाभ घेऊ शकता. तुम्ही दररोज अॅप
          उघडून अॅप ठेवण्यासाठी दररोज रिवॉर्ड मिळवू शकता आणि शेवटच्या दिवशी
          तुम्ही उर्वरित रिवॉर्डवर दावा करू शकता
        </div>
      ),
    },
    gu: {
      title: (
        <h4>વધારાનું પુરસ્કાર શું છે? હું તેનો દાવો કેવી રીતે કરી શકું?</h4>
      ),
      desc: (
        <div>
          એકવાર તમે ઑફર માટે આપેલા પગલાં પૂર્ણ કરી લો, પછી તમે વધારાના
          પુરસ્કારનો લાભ લેવા માટે પાત્ર છો. તમે ઉલ્લેખિત દિવસો સુધી ઑફરમાંથી
          ડાઉનલોડ કરેલી એપ્લિકેશન રાખીને તેનો લાભ લઈ શકો છો. તમે દરરોજ એપ્લિકેશન
          ખોલીને એપ્લિકેશન રાખવા માટે દૈનિક પુરસ્કારો મેળવી શકો છો અને છેલ્લા
          દિવસે તમે બાકીના પુરસ્કારનો દાવો કરી શકો છો
        </div>
      ),
    },
  },
  {
    key: 'why-wasnt-i-able-to-claim-my-additiona-reward',
    hi: {
      title: <h4>मैं अपना अतिरिक्त रिवॉर्ड क्यों नहीं अर्जित कर पाया?</h4>,
      desc: (
        <div>
          <p>
            आपका अतिरिक्त रिवॉर्ड निम्नलिखित कारणों से आपके खाते में क्रेडिट
            नहीं किया जाता है:
          </p>

          <ul>
            <li>आपने बताए गए समय से पहले ऐप को अनइंस्टॉल कर दिया।</li>
            <li>आपने रोजाना ऐप नहीं खोला।</li>
            <li>आपने दैनिक आधार पर रिडीम बटन पर क्लिक नहीं किया।</li>
          </ul>
        </div>
      ),
    },
    en: {
      title: <h4>Why wasn’t I able to claim my additional reward?</h4>,
      desc: (
        <div>
          <p>
            Your additional reward is not credited to your account due to the
            following reason:
          </p>

          <ul>
            <li>You uninstalled the app before the time mentioned.</li>
            <li>You did not open the App daily.</li>
            <li>You did not click the redeem button on a daily basis.</li>
          </ul>
        </div>
      ),
    },
    mr: {
      title: <h4>मी माझ्या अतिरिक्त रिवॉर्डवर दावा का करू शकलो नाही?</h4>,
      desc: (
        <div>
          <p>
            खालील कारणामुळे तुमचे अतिरिक्त बक्षीस तुमच्या खात्यात जमा झाले नाही:
          </p>

          <ul>
            <li>तुम्ही नमूद केलेल्या वेळेपूर्वी अॅप अनइंस्टॉल केले.</li>
            <li>तुम्ही रोज अॅप उघडत नाही.</li>
            <li>तुम्ही दररोज रिडीम बटणावर क्लिक केले नाही.</li>
          </ul>
        </div>
      ),
    },
    gu: {
      title: <h4>હું મારા વધારાના પુરસ્કારનો દાવો કેમ કરી શક્યો ન હતો?</h4>,
      desc: (
        <div>
          <p>
            નીચેના કારણોસર તમારો વધારાનો પુરસ્કાર તમારા ખાતામાં જમા થયો નથી:
          </p>

          <ul>
            <li>તમે ઉલ્લેખિત સમય પહેલા એપ્લિકેશનને અનઇન્સ્ટોલ કરી દીધી છે.</li>
            <li>તમે રોજ એપ ખોલતા નથી.</li>
            <li>તમે દૈનિક ધોરણે રિડીમ બટન પર ક્લિક કર્યું નથી.</li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'why-should-i-give-my-phone-number-for-logging-in',
    hi: {
      title: <h4>मुझे लॉग इन करने के लिए अपना फोन नंबर क्यों देना चाहिए?</h4>,
      desc: (
        <div>
          एक फ़ोन नंबर एक उपयोगकर्ता की एक विशिष्ट आईडी है और यह हमें उपयोगकर्ता
          द्वारा एप्लिकेशन पर की जा रही गतिविधि के आधार पर उसे प्रबंधित करने और
          बेहतर ऑफ़र देने में मदद करता है।
        </div>
      ),
    },
    en: {
      title: <h4>Why should I give my phone number for logging in?</h4>,
      desc: (
        <div>
          It’s simple, go to the Signup and enter your phone number. You will
          receive a SMS with OTP. Enter the OTP on InCash App. You are ready to
          earn the rewards and enjoy the offers.
        </div>
      ),
    },
    mr: {
      title: <h4>लॉग इन करण्यासाठी मी माझा फोन नंबर का द्यावा?</h4>,
      desc: (
        <div>
          हे सोपे आहे, साइनअप वर जा आणि तुमचा फोन नंबर प्रविष्ट करा. तुम्हाला
          OTP सह एक SMS प्राप्त होईल. InCash अॅपवर OTP टाका. तुम्ही रिवॉर्ड
          मिळवण्यासाठी आणि ऑफरचा आनंद घेण्यासाठी तयार आहात.
        </div>
      ),
    },
    gu: {
      title: <h4>લોગ ઇન કરવા માટે મારે મારો ફોન નંબર શા માટે આપવો જોઈએ?</h4>,
      desc: (
        <div>
          તે સરળ છે, સાઇનઅપ પર જાઓ અને તમારો ફોન નંબર દાખલ કરો. તમને OTP સાથે એક
          SMS પ્રાપ્ત થશે. InCash એપ પર OTP દાખલ કરો. તમે પુરસ્કારો મેળવવા અને
          ઑફર્સનો આનંદ લેવા માટે તૈયાર છો.
        </div>
      ),
    },
  },
  {
    key: 'is-there-any-other-method-of-logging-in',
    hi: {
      title: <h4>क्या लॉग इन करने का कोई अन्य तरीका है?</h4>,
      desc: (
        <div>
          फिलहाल एक उपयोगकर्ता केवल अपने फोन नंबर और ओटीपी पुष्टि का उपयोग करके
          ही लॉग इन कर सकता है।
        </div>
      ),
    },
    en: {
      title: <h4>Is there any other method of logging in?</h4>,
      desc: (
        <div>
          A phone number is a unique ID of a user and it helps us to manage and
          give better offers to a user based on the activity he is doing on the
          Application.
        </div>
      ),
    },
    mr: {
      title: <h4>लॉग इन करण्याची दुसरी पद्धत आहे का?</h4>,
      desc: (
        <div>
          फोन नंबर हा वापरकर्त्याचा एक अद्वितीय आयडी असतो आणि तो आम्हाला
          वापरकर्त्याच्या ऍप्लिकेशनवर करत असलेल्या क्रियाकलापाच्या आधारावर
          व्यवस्थापित करण्यास आणि त्याला चांगल्या ऑफर देण्यास मदत करतो.
        </div>
      ),
    },
    gu: {
      title: <h4>શું લૉગ ઇન કરવાની બીજી કોઈ પદ્ધતિ છે?</h4>,
      desc: (
        <div>
          ફોન નંબર એ વપરાશકર્તાની અનન્ય ID છે અને તે એપ્લિકેશન પર તે જે
          પ્રવૃત્તિ કરી રહ્યો છે તેના આધારે વપરાશકર્તાને વધુ સારી ઑફરોનું સંચાલન
          કરવામાં અને તેને આપવામાં મદદ કરે છે.
        </div>
      ),
    },
  },
  {
    key: 'what-are-trending-offers',
    hi: {
      title: <h4>ट्रेंडिंग ऑफ़र क्या हैं?</h4>,
      desc: (
        <div>
          ट्रेंडिंग ऑफ़र ऐसे ऑफ़र हैं जिनके लिए रिवॉर्ड मिलने की संभावना बहुत
          अधिक होती है और कार्य को पूरा करने के चरण बहुत आसान होते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>What are Trending Offers?</h4>,
      desc: (
        <div>
          Trending Offers are the offers for which the chances of getting a
          reward is very high and the steps to complete the task are very easy.
        </div>
      ),
    },
    mr: {
      title: <h4>ट्रेंडिंग ऑफर काय आहेत?</h4>,
      desc: (
        <div>
          ट्रेंडिंग ऑफर अशा ऑफर आहेत ज्यासाठी बक्षीस मिळण्याची शक्यता खूप जास्त
          आहे आणि कार्य पूर्ण करण्यासाठी पायऱ्या खूप सोप्या आहेत.
        </div>
      ),
    },
    gu: {
      title: <h4>ટ્રેન્ડિંગ ઑફર્સ શું છે?</h4>,
      desc: (
        <div>
          ટ્રેન્ડિંગ ઑફર્સ એવી ઑફર્સ છે કે જેના માટે પુરસ્કાર મેળવવાની શક્યતાઓ
          ઘણી વધારે છે અને કાર્ય પૂર્ણ કરવાના પગલાં ખૂબ જ સરળ છે.
        </div>
      ),
    },
  },
  {
    key: 'what-are-the-highest-paying-offers',
    hi: {
      title: <h4>सर्वाधिक कमाई वाले ऑफ़र क्या हैं?</h4>,
      desc: (
        <div>
          सर्वाधिक कमाई वाले ऑफ़र वे हैं जिनके लिए उपयोगकर्ताओं को दिया जाने
          वाला इनाम सभी ऑफ़र में सबसे अधिक है।
        </div>
      ),
    },
    en: {
      title: <h4>What are the highest paying offers?</h4>,
      desc: (
        <div>
          Highest Paying Offers are the ones for which the reward given to the
          users is highest among all the offers.
        </div>
      ),
    },
    mr: {
      title: <h4>सर्वाधिक पैसे देणाऱ्या ऑफर काय आहेत?</h4>,
      desc: (
        <div>
          सर्वाधिक पैसे देणाऱ्या ऑफर म्हणजे ज्यासाठी वापरकर्त्यांना दिले जाणारे
          बक्षीस सर्व ऑफर्समध्ये सर्वाधिक असते.
        </div>
      ),
    },
    gu: {
      title: <h4>સૌથી વધુ ચૂકવણી કરતી ઑફર્સ શું છે?</h4>,
      desc: (
        <div>
          સૌથી વધુ ચૂકવણી કરવાની ઑફરો એ છે જેના માટે વપરાશકર્તાઓને આપવામાં આવતો
          પુરસ્કાર તમામ ઑફર્સમાં સૌથી વધુ હોય છે.
        </div>
      ),
    },
  },
  {
    key: 'why-has-my-offer-failed-even-when-i-have-completed-all-the-tasks',
    hi: {
      title: (
        <h4>
          जब मैंने सभी चरणों को पूरा कर लिया है तब भी मेरा ऑफ़र फेल क्यों है?
        </h4>
      ),
      desc: (
        <div>
          <p>किसी ऑफ़र को निम्नलिखित कारणों से फेल घोषित किया जा सकता है:</p>
          <ul>
            <li>
              आपने ऑफ़र समाप्त होने की समयावधि से पहले ऑफ़र को पूरा नहीं किया।
            </li>
            <li>ऑफ़र के लिए सभी चरण पूरे नहीं किए गए थे।</li>
            <li>
              हमें विज्ञापनदाताओं की ओर से ऑफ़र पूरा होने की पुष्टि नहीं मिली।
            </li>
          </ul>
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          Why has my offer failed even when I have completed all the tasks?
        </h4>
      ),
      desc: (
        <div>
          <p>
            An offer may be declared as failed because of the following reasons:
          </p>
          <ul>
            <li>
              You did not complete the offer before the time duration for the
              offer ended.
            </li>
            <li>All the steps were not completed for the offer</li>
            <li>
              We did not get a confirmation for the offer completion from
              advertisers side.
            </li>
          </ul>
        </div>
      ),
    },
    mr: {
      title: <h4>मी सर्व टास्क पूर्ण करूनही माझी ऑफर अयशस्वी का झाली?</h4>,
      desc: (
        <div>
          <p>खालील कारणांमुळे ऑफर अयशस्वी म्हणून घोषित केली जाऊ शकते:</p>
          <ul>
            <li>ऑफरचा कालावधी संपण्यापूर्वी तुम्ही ऑफर पूर्ण केली नाही.</li>
            <li>ऑफरसाठी सर्व पायऱ्या पूर्ण झाल्या नाहीत</li>
            <li>
              आम्हाला जाहिरातदारांकडून ऑफर पूर्ण झाल्याची पुष्टी मिळाली नाही.
            </li>
          </ul>
        </div>
      ),
    },
    gu: {
      title: (
        <h4>મેં તમામ કાર્યો પૂર્ણ કર્યા હોવા છતાં મારી ઑફર કેમ નિષ્ફળ ગઈ?</h4>
      ),
      desc: (
        <div>
          <p>નીચેના કારણોસર ઑફરને નિષ્ફળ તરીકે જાહેર કરી શકાય છે:</p>
          <ul>
            <li>ઑફરની સમય અવધિ સમાપ્ત થાય તે પહેલાં તમે ઑફર પૂર્ણ કરી નથી.</li>
            <li>ઓફર માટે તમામ પગલાં પૂર્ણ થયા ન હતા</li>
            <li>અમને જાહેરાતકર્તાઓ તરફથી ઑફર પૂર્ણ થવાની પુષ્ટિ મળી નથી.</li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'complete-an-offer-even-after-the-status-for-it-is-Failed',
    hi: {
      title: (
        <h4>
          क्या मैं किसी ऑफ़र की स्थिति फेल होने के बाद भी उसे पूरा कर सकता हूं?
        </h4>
      ),
      desc: (
        <div>
          हाँ, आप ऑफ़र को पूरा कर सकते हैं, भले ही आपके लिए ऑफ़र की स्थिति तब तक
          विफल हो जब तक यह ऐप पर सक्रिय है। यदि ऑफ़र निष्क्रिय है तो आपको कोई
          रिवॉर्ड नहीं मिलेगा।
        </div>
      ),
    },
    en: {
      title: (
        <h4>Can I complete an offer even after the status for it is Failed?</h4>
      ),
      desc: (
        <div>
          Yes, you can complete the offer even if the status of the offer for
          you is Failed as long as it is active on the App. If the offer is
          inactive you won’t get any rewards.
        </div>
      ),
    },
    mr: {
      title: <h4>ऑफरची स्थिती अयशस्वी झाल्यानंतरही मी पूर्ण करू शकतो का?</h4>,
      desc: (
        <div>
          होय, तुमच्यासाठी ऑफरची स्थिती अयशस्वी झाली असली तरीही तुम्ही ऑफर पूर्ण
          करू शकता जोपर्यंत ती अॅपवर सक्रिय आहे. ऑफर निष्क्रिय असल्यास तुम्हाला
          कोणतेही पुरस्कार मिळणार नाहीत.
        </div>
      ),
    },
    gu: {
      title: <h4>શું હું ઑફરનું સ્ટેટસ નિષ્ફળ ગયા પછી પણ પૂર્ણ કરી શકું?</h4>,
      desc: (
        <div>
          હા, જો તમારા માટે ઑફરનું સ્ટેટસ નિષ્ફળ થયું હોય તો પણ તમે ઑફર પૂર્ણ
          કરી શકો છો જ્યાં સુધી તે ઍપ પર સક્રિય છે. જો ઑફર નિષ્ક્રિય હોય તો તમને
          કોઈ પુરસ્કારો મળશે નહીં.
        </div>
      ),
    },
  },
  {
    key: 'completed-all-the-steps-for-my-offer-but-didnt-get-any-rewards',
    hi: {
      title: (
        <h4>
          मैंने अपने ऑफ़र के लिए सभी चरण पूरे कर लिए हैं लेकिन मुझे कोई रिवॉर्ड
          नहीं मिला है। क्या समस्या है?
        </h4>
      ),
      desc: (
        <div>
          यदि आपने किसी ऑफ़र के लिए सभी चरणों को पूरा कर लिया है और आपको कोई
          रिवॉर्ड नहीं मिला है, तो आपके ऑफ़र के साथ ट्रैकिंग समस्या हो सकती है
          और हो सकता है कि हमें विज्ञापनदाताओं की ओर से पुष्टि प्राप्त नहीं हुई
          हो। कृपया हमें अपने फोन नंबर और ऑफ़र के नाम के साथ{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          पर लिखें और हम आपके लिए समस्या का समाधान करने का प्रयास करेंगे।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          I completed all the steps for my offer but didn’t get any rewards.
          What was the issue?
        </h4>
      ),
      desc: (
        <div>
          If you have completed all the steps for an offer and didn’t get any
          rewards, there might be a tracking issue with your offer and we might
          have not received the confirmation for the advertisers side. Please
          write to us at{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          with your Phone number and Offer Name and we will try to solve the
          issue for you.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          मी माझ्या ऑफरसाठी सर्व पायऱ्या पूर्ण केल्या पण मला कोणतेही बक्षीस
          मिळाले नाहीत. मुद्दा काय होता?
        </h4>
      ),
      desc: (
        <div>
          तुम्ही ऑफरसाठी सर्व पायऱ्या पूर्ण केल्या असतील आणि तुम्हाला कोणतेही
          रिवॉर्ड मिळाले नसतील, तर तुमच्या ऑफरमध्ये ट्रॅकिंग समस्या असू शकते आणि
          आम्हाला जाहिरातदारांच्या बाजूचे पुष्टीकरण मिळालेले नसेल. कृपया तुमचा
          फोन नंबर आणि ऑफर नावासह आम्हाला{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          वर लिहा आणि आम्ही तुमच्यासाठी समस्या सोडवण्याचा प्रयत्न करू.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          મેં મારી ઑફર માટેના તમામ પગલાં પૂર્ણ કર્યા છે પરંતુ મને કોઈ પુરસ્કાર
          મળ્યો નથી. શું હતો મુદ્દો?
        </h4>
      ),
      desc: (
        <div>
          જો તમે ઑફર માટેના તમામ પગલાં પૂર્ણ કરી લીધા હોય અને તમને કોઈ પુરસ્કાર
          ન મળ્યા હોય, તો તમારી ઑફરમાં ટ્રૅકિંગની સમસ્યા હોઈ શકે છે અને અમને
          જાહેરાતકર્તાઓ તરફથી પુષ્ટિ મળી નથી. કૃપા કરીને તમારા ફોન નંબર અને ઑફર
          નામ સાથે અમને{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          પર લખો અને અમે તમારા માટે સમસ્યા હલ કરવાનો પ્રયાસ કરીશું
        </div>
      ),
    },
  },
  {
    key: 'locally-verified-status-of-an-offer',
    hi: {
      title: (
        <h4>किसी ऑफ़र की लोकल वेरिफाइड स्थिति से आपका क्या तात्पर्य है?</h4>
      ),
      desc: (
        <div>
          लोकल वेरिफाइड ऑफ़र का अर्थ है कि ऑफ़र के ऐप की स्थापना को हमारी ओर से
          ट्रैक किया गया है और हम विज्ञापनदाताओं से इस बात की पुष्टि की
          प्रतीक्षा कर रहे हैं कि ऐप इंस्टॉल को उनकी तरफ से भी ट्रैक किया गया
          है।
        </div>
      ),
    },
    en: {
      title: <h4>What do you mean by Locally Verified status of an offer?</h4>,
      desc: (
        <div>
          Locally Verified offer means that the installation of the app of the
          offer has been tracked at our end and we are waiting for a
          confirmation from the advertisers that the app install has been
          tracked on his side too.
        </div>
      ),
    },
    mr: {
      title: <h4>ऑफरच्या स्थानिक सत्यापित स्थितीचा तुम्हाला काय अर्थ आहे?</h4>,
      desc: (
        <div>
          लोकल व्हेरिफाईड ऑफरचा अर्थ असा आहे की ऑफरच्या अॅपची स्थापना आमच्या
          शेवटी ट्रॅक केली गेली आहे आणि आम्ही जाहिरातदारांच्या पुष्टीकरणाची वाट
          पाहत आहोत की अॅप इंस्टॉल त्याच्या बाजूने देखील ट्रॅक केला गेला आहे
        </div>
      ),
    },
    gu: {
      title: <h4>ઑફરની સ્થાનિક રીતે ચકાસાયેલ સ્થિતિનો તમારો અર્થ શું છે?</h4>,
      desc: (
        <div>
          સ્થાનિક રીતે વેરિફાઈડ ઑફરનો અર્થ એ છે કે ઑફરની ઍપનું ઇન્સ્ટૉલેશન અમારા
          અંતે ટ્રૅક કરવામાં આવ્યું છે અને અમે જાહેરાતકર્તાઓ તરફથી પુષ્ટિની રાહ
          જોઈ રહ્યા છીએ કે ઍપ ઇન્સ્ટૉલને તેમની બાજુએ પણ ટ્રૅક કરવામાં આવી છે.
        </div>
      ),
    },
  },
  {
    key: 'status-of-my-offer-changes-from-locally-verified-to-completed',
    hi: {
      title: (
        <h4>
          मेरे ऑफ़र की स्थिति लोकल वेरिफाइड से कम्प्लीटेड में कब बदलती है?
        </h4>
      ),
      desc: (
        <div>
          एक बार जब हमें विज्ञापनदाताओं से यह पुष्टि मिल जाती है कि आपने ऑफ़र
          विवरण में उल्लिखित सभी चरणों को पूरा कर लिया है, तो ऑफ़र की स्थिति
          लोकल वेरिफाइड से कम्प्लीटेड में बदल जाती है। जैसे ही ऑफ़र की स्थिति
          कम्प्लीटेड हो जाती है, हम आपके इनकॅश वॉलेट में पुरस्कार जोड़ देते हैं।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          When does the status of my offer changes from Locally Verified to
          Completed?
        </h4>
      ),
      desc: (
        <div>
          The offer status changes from Locally Verified to Completed once we
          receive confirmation from advertisers that you have completed all the
          steps mentioned in the offer details. As soon as the status of the
          offer changes to completed we add rewards in your InCash wallet.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>माझ्या ऑफरची स्थिती लोकल व्हेरिफाईड वरून पूर्ण केव्हा बदलते?</h4>
      ),
      desc: (
        <div>
          ऑफरच्या तपशिलांमध्ये नमूद केलेल्या सर्व पायऱ्या तुम्ही पूर्ण केल्याचे
          जाहिरातदारांकडून पुष्टीकरण मिळाल्यावर ऑफरची स्थिती स्थानिक पडताळणीवरून
          पूर्ण झाली. ऑफरची स्थिती पूर्ण झाल्यावर आम्ही तुमच्या InCash
          वॉलेटमध्ये रिवॉर्ड जोडतो.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          મારી ઑફરની સ્થિતિ ક્યારે સ્થાનિક રીતે ચકાસાયેલમાંથી પૂર્ણ થઈ જાય છે?
        </h4>
      ),
      desc: (
        <div>
          એકવાર અમને જાહેરાતકર્તાઓ તરફથી પુષ્ટિ મળે કે તમે ઑફરની વિગતોમાં
          ઉલ્લેખિત તમામ પગલાં પૂર્ણ કરી લીધાં પછી ઑફરની સ્થિતિ સ્થાનિક રીતે
          ચકાસાયેલમાંથી પૂર્ણ થઈ જાય છે. ઑફરનું સ્ટેટસ પૂર્ણ થતાં જ અમે તમારા
          InCash વૉલેટમાં પુરસ્કારો ઉમેરીશું.
        </div>
      ),
    },
  },
  {
    key: 'advertisers-to-give-confirmation',
    hi: {
      title: (
        <h4>
          मेरे द्वारा सभी चरणों को पूरा करने के बाद विज्ञापनदाताओं को पुष्टिकरण
          देने में कितना समय लगता है?
        </h4>
      ),
      desc: (
        <div>
          सभी ऑफ़र के लिए पुष्टिकरण रीयलटाइम है और रीयलटाइम में आपके वॉलेट में
          इनाम जोड़ा जाता है। कुछ विशेष मामलों में विज्ञापनदाताओं को पुष्टि देने
          में 24 घंटे तक लग सकते हैं। 24 घंटों के बाद अगर हमें विज्ञापनदाताओं की
          ओर से पुष्टि नहीं मिलती है, तो ऑफ़र की स्थिति फेल हो जाती है।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          How much time does it take for the advertisers to give confirmation
          once I complete all the steps?
        </h4>
      ),
      desc: (
        <div>
          For all the offers the confirmation is realtime and reward is added to
          your wallet in realtime. For some special cases advertisers may take
          upto 24 hours to give the confirmation. After 24 hours if we don’t get
          a confirmation from the advertisers side, the offer status changes to
          failed.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          मी सर्व पायऱ्या पूर्ण केल्यावर जाहिरातदारांना पुष्टीकरण देण्यासाठी
          किती वेळ लागेल?
        </h4>
      ),
      desc: (
        <div>
          सर्व ऑफरसाठी पुष्टीकरण रिअलटाइम आहे आणि रिअलटाइममध्ये रिवॉर्ड तुमच्या
          वॉलेटमध्ये जोडले जाते. काही विशेष प्रकरणांसाठी जाहिरातदारांना
          पुष्टीकरण देण्यासाठी 24 तास लागू शकतात. 24 तासांनंतर आम्हाला
          जाहिरातदारांकडून पुष्टीकरण न मिळाल्यास, ऑफरची स्थिती अयशस्वी होईल.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          એકવાર હું તમામ પગલાં પૂર્ણ કરી લઉં તે પછી જાહેરાતકર્તાઓને કન્ફર્મેશન
          આપવામાં કેટલો સમય લાગે છે?
        </h4>
      ),
      desc: (
        <div>
          તમામ ઓફર્સ માટે કન્ફર્મેશન રીયલટાઇમ છે અને રીયલટાઇમમાં તમારા વોલેટમાં
          પુરસ્કાર ઉમેરવામાં આવે છે. કેટલાક ખાસ કેસ માટે જાહેરાતકર્તાઓને પુષ્ટિ
          આપવામાં 24 કલાક જેટલો સમય લાગી શકે છે. 24 કલાક પછી જો અમને
          જાહેરાતકર્તાઓ તરફથી પુષ્ટિ ન મળે, તો ઑફરની સ્થિતિ નિષ્ફળ થઈ જાય છે.
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-refer-my-friends-from-InCash',
    hi: {
      title: <h4>मैं अपने दोस्तों को इनकॅश से कैसे रेफर कर सकता हूं?</h4>,
      desc: (
        <div>
          अपने दोस्तों को रेफ़र करने के लिए, अपनी प्रोफ़ाइल में रेफ़र एंड अर्न
          सेगमेंट पर जाएँ। आपको स्क्रीन के नीचे अपना रेफ़रल कोड मिलेगा जिसे आप
          कॉपी कर सकते हैं और सीधे अपने दोस्तों के साथ शेयर कर सकते हैं या शेयर
          सूची में दिए गए ऐप्स के माध्यम से शेयर कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>How can I Refer my Friends from InCash?</h4>,
      desc: (
        <div>
          To refer your friends, go to the Refer and Earn segment in your
          profile. You will find your Referral code at the bottom of the screen
          which you can copy and share directly with your friends or share it
          via the apps given in the share list.
        </div>
      ),
    },
    mr: {
      title: <h4>मी InCash वरून माझ्या मित्रांना कसे संदर्भ देऊ शकतो?</h4>,
      desc: (
        <div>
          तुमच्या मित्रांना संदर्भ देण्यासाठी, तुमच्या प्रोफाइलमधील संदर्भ आणि
          कमवा विभागावर जा. तुम्हाला तुमचा रेफरल कोड स्क्रीनच्या तळाशी मिळेल जो
          तुम्ही कॉपी करून थेट तुमच्या मित्रांसह शेअर करू शकता किंवा शेअर
          लिस्टमध्ये दिलेल्या अॅप्सद्वारे शेअर करू शकता.
        </div>
      ),
    },
    gu: {
      title: <h4>હું InCash પરથી મારા મિત્રોને કેવી રીતે રેફર કરી શકું?</h4>,
      desc: (
        <div>
          તમારા મિત્રોને રેફર કરવા માટે, તમારી પ્રોફાઇલમાં રેફર એન્ડ અર્ન
          સેગમેન્ટ પર જાઓ. તમને તમારો રેફરલ કોડ સ્ક્રીનના તળિયે મળશે જેને તમે
          કોપી કરી શકો છો અને સીધા તમારા મિત્રો સાથે શેર કરી શકો છો અથવા શેર
          લિસ્ટમાં આપેલી એપ્સ દ્વારા શેર કરી શકો છો.
        </div>
      ),
    },
  },
  {
    key: 'when-will-i-get-the-referral-reward',
    hi: {
      title: (
        <h4>
          मेरे द्वारा रेफर किए गए दोस्तों के लिए मुझे रेफ़रल रिवॉर्ड कब मिलेगा?
        </h4>
      ),
      desc: (
        <div>
          साइन अप करते समय आपके द्वारा रेफर मित्र को आपका रेफ़रल कोड दर्ज करना
          होगा। एक बार जब उसने साइन इन किया तो उसे तीन ऑफर का लाभ उठाना होगा।
          जैसे ही वह अपने तीन ऑफर को पूरा करेगा, आपके दोनों के इनकॅश वॉलेट में
          रिवॉर्ड जुड़ जाएंगे।
        </div>
      ),
    },
    en: {
      title: (
        <h4>When will I get the referral reward for the friends I referred?</h4>
      ),
      desc: (
        <div>
          The Friend you referred has to enter your referral code while Sign Up.
          Once he Signed in he has to avail three offers. As soon as he
          completes his three offers, rewards will be added in both of your
          InCash Wallets.
        </div>
      ),
    },
    mr: {
      title: <h4>मी रेफर केलेल्या मित्रांसाठी रेफरल रिवॉर्ड कधी मिळेल?</h4>,
      desc: (
        <div>
          साइन अप करताना तुम्ही संदर्भित केलेल्या मित्राला तुमचा रेफरल कोड
          टाकावा लागेल. एकदा त्याने साइन इन केल्यावर त्याला तीन ऑफर घ्याव्या
          लागतील. त्याने त्याच्या तीन ऑफर पूर्ण केल्यावर, तुमच्या दोन्ही InCash
          Wallets मध्ये रिवॉर्ड्स जोडले जातील.
        </div>
      ),
    },
    gu: {
      title: <h4>મેં રેફર કરેલા મિત્રો માટે રેફરલ પુરસ્કાર ક્યારે મળશે?</h4>,
      desc: (
        <div>
          તમે જે મિત્રનો ઉલ્લેખ કર્યો છે તેણે સાઇન અપ કરતી વખતે તમારો રેફરલ કોડ
          દાખલ કરવો પડશે. એકવાર તેણે સાઇન ઇન કર્યા પછી તેણે ત્રણ ઑફર્સનો લાભ
          લેવો પડશે. જલદી તે તેની ત્રણ ઑફર્સ પૂર્ણ કરશે, તમારા બંને InCash
          Walletsમાં પુરસ્કારો ઉમેરવામાં આવશે.
        </div>
      ),
    },
  },
  {
    key: 'what-are-the-rewards-me-and-my-friend',
    hi: {
      title: (
        <h4>
          अगर मैं अपने दोस्त को रेफर कर दूं तो मुझे और मेरे दोस्त को कितना इनाम
          मिलेगा?
        </h4>
      ),
      desc: (
        <div>
          यदि आप अपने मित्र को रेफर करते हैं और वह तीन प्रस्तावों को पूरा करता
          है, तो आपको अपने खाते में 50 इनकॅश मिलते हैं जबकि आपके मित्र को उसके
          खाते में 20 इनकॅश मिलते हैं।
        </div>
      ),
    },
    en: {
      title: (
        <h4>What are the rewards me and my friend will get if I refer him?</h4>
      ),
      desc: (
        <div>
          If your refer your friend and he completes three offers, you get 50
          InCashes in your account while your friend gets 20 InCashes in his
          account.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          मी त्याचा संदर्भ दिल्यास मला आणि माझ्या मित्राला कोणते पुरस्कार
          मिळतील?
        </h4>
      ),
      desc: (
        <div>
          जर तुम्ही तुमच्या मित्राला रेफर केले आणि त्याने तीन ऑफर पूर्ण केल्या,
          तर तुम्हाला तुमच्या खात्यात 50 InCashes मिळतात तर तुमच्या मित्राला
          त्याच्या खात्यात 20 InCashes मिळतात.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          જો હું તેને સંદર્ભિત કરું તો મને અને મારા મિત્રને શું પુરસ્કારો મળશે?
        </h4>
      ),
      desc: (
        <div>
          જો તમે તમારા મિત્રને રેફર કરો છો અને તે ત્રણ ઑફર્સ પૂર્ણ કરે છે, તો
          તમને તમારા ખાતામાં 50 InCashes મળે છે જ્યારે તમારા મિત્રને તેના
          ખાતામાં 20 InCashes મળે છે.
        </div>
      ),
    },
  },
  {
    key: 'share-my-referral-code-with-my-friend',
    hi: {
      title: (
        <h4>मैं अपने रेफ़रल कोड को अपने मित्र के साथ कैसे शेयर कर सकता हूँ?</h4>
      ),
      desc: (
        <div>
          आप सूची में दिए गए किसी भी चैनल के माध्यम से रेफरल कोड शेयर कर सकते
          हैं या फिर आप रेफरल कोड की प्रतिलिपि बना सकते हैं और इसे अपनी पसंद के
          चैनल के माध्यम से शेयर कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>How can I share my referral code with my friend?</h4>,
      desc: (
        <div>
          You can share referral code through any channel given in the list or
          else you can copy the referral code and share it through the channel
          of your preference.
        </div>
      ),
    },
    mr: {
      title: <h4>मी माझा रेफरल कोड माझ्या मित्रासोबत कसा शेअर करू शकतो?</h4>,
      desc: (
        <div>
          तुम्ही सूचीमध्ये दिलेल्या कोणत्याही चॅनेलद्वारे रेफरल कोड शेअर करू
          शकता अन्यथा तुम्ही रेफरल कोड कॉपी करून तुमच्या पसंतीच्या चॅनेलद्वारे
          शेअर करू शकता.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>હું મારા મિત્ર સાથે મારો રેફરલ કોડ કેવી રીતે શેર કરી શકું?</h4>
      ),
      desc: (
        <div>
          તમે સૂચિમાં આપેલ કોઈપણ ચેનલ દ્વારા રેફરલ કોડ શેર કરી શકો છો અથવા તો
          તમે રેફરલ કોડની નકલ કરી શકો છો અને તેને તમારી પસંદગીની ચેનલ દ્વારા શેર
          કરી શકો છો.
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-go-to-my-wallet',
    hi: {
      title: <h4>मैं अपने वॉलेट में कैसे जा सकता हूं?</h4>,
      desc: (
        <div>
          आप ऐप में सबसे ऊपर वॉलेट आइकन पर क्लिक करके वॉलेट में जा सकते हैं।
          प्रदर्शित राशि वह कुल राशि है जो आपने इनकॅश के माध्यम से ऐप पर अर्जित
          की है।
        </div>
      ),
    },
    en: {
      title: <h4>How can I go to my wallet?</h4>,
      desc: (
        <div>
          You can go to the wallet by clicking on the Wallet Icon at the top of
          the App. The amount displayed is the total amount you have earned on
          the App via InCash.
        </div>
      ),
    },
    mr: {
      title: <h4>मी माझ्या वॉलेटमध्ये कसे जाऊ शकतो?</h4>,
      desc: (
        <div>
          अॅपच्या शीर्षस्थानी असलेल्या वॉलेट चिन्हावर क्लिक करून तुम्ही वॉलेटवर
          जाऊ शकता. प्रदर्शित केलेली रक्कम ही तुम्ही InCash द्वारे अॅपवर
          कमावलेली एकूण रक्कम आहे.
        </div>
      ),
    },
    gu: {
      title: <h4>હું મારા વૉલેટમાં કેવી રીતે જઈ શકું?</h4>,
      desc: (
        <div>
          તમે એપની ટોચ પર વોલેટ આઇકોન પર ક્લિક કરીને વોલેટમાં જઈ શકો છો.
          દર્શાવેલ રકમ એ તમે InCash મારફતે એપ પર કમાયેલી કુલ રકમ છે
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-see-my-earning-history',
    hi: {
      title: <h4>मैं अपनी अर्निंग हिस्ट्री कैसे देख सकता हूँ?</h4>,
      desc: (
        <div>
          अपनी अर्निंग हिस्ट्री देखने के लिए वॉलेट में जाएं और अर्निंग हिस्ट्री
          टैब पर क्लिक करें। वहां आप अपनी सारी कमाई देख सकते हैं जो आपने कार्य
          सौदे ऐप पर की है।
        </div>
      ),
    },
    en: {
      title: <h4>How can I see my earning history?</h4>,
      desc: (
        <div>
          To check your earning history, go to the wallet and click on the
          Earning History tab. There you can see all your earnings that you have
          done on InCash App.
        </div>
      ),
    },
    mr: {
      title: <h4>मी माझ्या कमाईचा इतिहास कसा पाहू शकतो?</h4>,
      desc: (
        <div>
          तुमचा कमाईचा इतिहास तपासण्यासाठी, वॉलेटवर जा आणि कमाईचा इतिहास टॅबवर
          क्लिक करा. तेथे तुम्ही InCash अॅपवर केलेली तुमची सर्व कमाई पाहू शकता.
        </div>
      ),
    },
    gu: {
      title: <h4>હું મારી કમાણીનો ઇતિહાસ કેવી રીતે જોઈ શકું?</h4>,
      desc: (
        <div>
          તમારી કમાણીનો ઇતિહાસ તપાસવા માટે, વૉલેટ પર જાઓ અને અર્નિંગ હિસ્ટ્રી
          ટેબ પર ક્લિક કરો. ત્યાં તમે તમારી બધી કમાણી જોઈ શકો છો જે તમે InCash
          એપ પર કરી છે.
        </div>
      ),
    },
  },
  {
    key: 'minimum-amount-i-can-transfer ',
    hi: {
      title: <h4>इनकॅश ऐप से मैं कितनी न्यूनतम राशि ट्रांसफर कर सकता हूं?</h4>,
      desc: (
        <div>
          पहले ट्रांजेक्शन के लिए न्यूनतम राशि जिसे आप कम से कम रु. 5. दूसरे
          ट्रांजेक्शन के बाद से हस्तांतरित की जा सकने वाली न्यूनतम राशि रु. 30.
        </div>
      ),
    },
    en: {
      title: (
        <h4>What is the minimum amount I can transfer from InCash App?</h4>
      ),
      desc: (
        <div>
          For the first transaction the minimum amount that you can transfer is
          as low as Rs. 5. From the second transaction onwards the minimum
          amount that can be transferred is Rs. 30.
        </div>
      ),
    },
    mr: {
      title: <h4>मी इनकॅश अॅपवरून किमान किती रक्कम हस्तांतरित करू शकतो?</h4>,
      desc: (
        <div>
          पहिल्या व्यवहारासाठी तुम्ही ट्रान्सफर करू शकणारी किमान रक्कम रु. इतकी
          कमी आहे. 5. दुस-या व्यवहारापासून ट्रान्सफर करता येणारी किमान रक्कम रु.
          ३०.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>હું InCash એપમાંથી ઓછામાં ઓછી કેટલી રકમ ટ્રાન્સફર કરી શકું?</h4>
      ),
      desc: (
        <div>
          પ્રથમ ટ્રાન્ઝેક્શન માટે તમે ટ્રાન્સફર કરી શકો તે ન્યૂનતમ રકમ રૂ. જેટલી
          ઓછી છે. 5. બીજા ટ્રાન્ઝેક્શનથી ટ્રાન્સફર કરી શકાય તેવી ન્યૂનતમ રકમ રૂ.
          30.
        </div>
      ),
    },
  },
  {
    key: 'channels-to-transfer-my-money',
    hi: {
      title: (
        <h4>इनकॅश ऐप से मेरे पैसे ट्रांसफर करने के लिए कौन से चैनल हैं?</h4>
      ),
      desc: (
        <div>
          आप अपना पैसा अपने पेटीएम वॉलेट में ट्रांसफर कर सकते हैं या यूपीआई आईडी
          का उपयोग करके सीधे अपने बैंक में राशि ट्रांसफर कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: (
        <h4>What are the channels to transfer my money from InCash App?</h4>
      ),
      desc: (
        <div>
          You can transfer your money to your Paytm Wallet or you can directly
          transfer the amount to your bank using the UPI ID.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          InCash अॅपवरून माझे पैसे हस्तांतरित करण्यासाठी कोणते चॅनेल आहेत?
        </h4>
      ),
      desc: (
        <div>
          तुम्ही तुमचे पैसे तुमच्या पेटीएम वॉलेटमध्ये ट्रान्सफर करू शकता किंवा
          तुम्ही UPI आयडी वापरून थेट तुमच्या बँकेत रक्कम ट्रान्सफर करू शकता
        </div>
      ),
    },
    gu: {
      title: <h4>InCash એપમાંથી મારા પૈસા ટ્રાન્સફર કરવા માટે કઈ ચેનલો છે?</h4>,
      desc: (
        <div>
          તમે તમારા પેટીએમ વોલેટમાં તમારા પૈસા ટ્રાન્સફર કરી શકો છો અથવા તમે UPI
          ID નો ઉપયોગ કરીને સીધી તમારી બેંકમાં રકમ ટ્રાન્સફર કરી શકો છો.
        </div>
      ),
    },
  },
  {
    key: 'how-much-time-will-it-take-to-transfer-money',
    hi: {
      title: (
        <h4>
          इनकॅश से मेरे बैंक खाते में पैसे ट्रांसफर करने में कितना समय लगेगा?
        </h4>
      ),
      desc: (
        <div>
          आमतौर पर अनुरोध किए जाने के कुछ सेकंड के भीतर स्थानांतरण हो जाता है,
          लेकिन परिस्थितियों के आधार पर बैंक को उनकी ओर से भुगतान संसाधित करने
          में 72 घंटे तक का समय लग सकता है।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          How much time will it take to transfer money from InCash to my Bank
          account?
        </h4>
      ),
      desc: (
        <div>
          Usually the transfer takes place within seconds of the request made
          but the bank may take upto 72 hours to process the payment from their
          side depending on the circumstances.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          InCash मधून माझ्या बँक खात्यात पैसे हस्तांतरित करण्यासाठी किती वेळ
          लागेल?
        </h4>
      ),
      desc: (
        <div>
          सहसा विनंती केल्‍याच्‍या काही सेकंदांमध्‍ये स्‍थानांतरण होते परंतु
          परिस्थितीनुसार बँकेला त्यांच्या बाजूने पेमेंट प्रक्रिया करण्‍यासाठी 72
          तास लागू शकतात.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          InCash થી મારા બેંક ખાતામાં નાણાં ટ્રાન્સફર કરવામાં કેટલો સમય લાગશે?
        </h4>
      ),
      desc: (
        <div>
          સામાન્ય રીતે વિનંતિની સેકન્ડોમાં ટ્રાન્સફર થાય છે પરંતુ બેંક સંજોગોના
          આધારે તેમની તરફથી ચુકવણીની પ્રક્રિયા કરવામાં 72 કલાક સુધીનો સમય લઈ શકે
          છે.
        </div>
      ),
    },
  },
  {
    key: 'money-is-not-getting-transferred',
    hi: {
      title: (
        <h4>
          मेरे पैसे मेरे बैंक खाते में स्थानांतरित नहीं हो रहे हैं, इसके कुछ कारण क्या हो सकते हैं?
        </h4>
      ),
      desc: (
        <div>
          <p>
            यहाँ वजहें हैं कि आपके पैसे आपके खाते में स्थानांतरित नहीं हो रहे हैं:
          </p>
          <ul>
            <li>
              <span>पैसे निकालते समय गलत UPID दर्ज किया गया है।</span>
              <p>[नोट: इस मामले में उपयोगकर्ता को असली पैसे नहीं मिलेंगे या यदि पुनरार्थित किए गए हैं, तो नकदी में वापस किए जाएंगे।]</p>
            </li>
            <li>
              <span>भुगतान गेटवे बंद है।</span>
              <p>[नोट: यदि भुगतान प्रसंस्कृत होता है तो उपयोगकर्ता को खाते में असली पैसे मिलेंगे या यदि भुगतान असफल होता है तो उपयोगकर्ता को वॉलेट में नकदी में रिफंड किया जाएगा।]</p>
            </li>
          </ul>
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          What are some of the reasons my money is not getting transferred to my
          bank account?
        </h4>
      ),
      desc: (
        <div>
          <p>
            The reasons why your money is not getting transferred to your
            account:
          </p>
          <ul>
            <li>
              Wrong UPID entered while withdrawing the money.
              <p>[Note: The user will not receive real money or refunded incash in this case.]</p>
            </li>
            <li>
              Payment gateway is down.
              <p>[Note: The user will get real money in the account if payment is processed or will get refunded incash in wallet if payment is failed.]</p>
            </li>
          </ul>
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          माझे पैसे माझ्या बँक खात्यात स्थानांतरित नाहीत, त्याचे काही कारण काय असू शकतात?
        </h4>
      ),
      desc: (
        <div>
          <p>
            तुमच्या पैस्यांच्या तुमच्या खात्यात स्थानांतरित होणार का किंवा नाही, त्याचे काही कारण खूपसा काय आहेत:
          </p>
          <ul>
            <li>
              <span>पैसे आकारताना चुकीचा UPID दिलेला आहे.</span>
              <p>[नोट: ह्या प्रकरणात वापरकर्ता कोणत्याही असल्या पैस्यांची प्राप्ती नसेल किंवा परत केलेल्या रोखात नकदी मिळवण्यात येईल.]</p>
            </li>
            <li>
              <span>पेमेंट गेटवे डाउन आहे.</span>
              <p>[नोट: पेमेंट प्रक्रियेत संपादित केल्यास वापरकर्त्याला खात्यात खर्च पैसे मिळतील किंवा पेमेंट अयशस्वी होत्यास वापरकर्त्याला वॉलेटमध्ये रिफंड होईल.]</p>
            </li>
          </ul>
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          મારા પૈસાં મારી બેંક એકાઉન્ટમાં ટ્રાન્સફર થતા નથી, તેમના કેટલાક કારણો શું હોઈ શકે છે?
        </h4>
      ),
      desc: (
        <div>
          <p>તમારા પૈસાંનું તમારા એકાઉન્ટમાં ટ્રાન્સફર થતા નથી, આ માટે કેટલાક કારણો આપોઆપ છે:</p>
          <ul>
            <li>
              <span>પૈસાં નીકાલવામાં ખોટું UPID દાખલ કર્યું છે.</span>
              <p>[નોંધ: આ મામલામાં વપરાશકર્તાને અસલા પૈસા મળતા નથી અથવા પેમેન્ટ ફેલ થયા છે તો વાલેટમાં નકદીમાં રિફંડ થશે.]</p>
            </li>
            <li>
              <span>પેમેન્ટ ગેટવે ડાઉન છે.</span>
              <p>[નોંધ: પેમેન્ટ પ્રક્રિયા પૂરી થઈ છે તો વપરાશકર્તાને ખાતે વાસ્તવિક પૈસા મળશે અથવા પેમેન્ટ અસફલ થઇ છે તો વપરાશકર્તાને વૉલેટમાં નકદીમાં રિફંડ થશે.]</p>
            </li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'unable-to-transfer-the-money',
    hi: {
      title: <h4>मुझे पैसे ट्रांसफर करने से प्रतिबंधित क्यों किया गया?</h4>,
      desc: (
        <div>
          आपको इनकॅश से पैसे ट्रांसफर करने पर प्रतिबंध लगा दिया गया है क्योंकि
          हो सकता है कि हमें आपके इनकॅश खाते में कुछ असामान्य गतिविधि का पता चला
          हो। अगर आपको लगता है कि आपको गलत तरीके से प्रतिबंधित किया गया है, तो
          हमें{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          पर अपने फोन नंबर के साथ अपनी समस्या का उल्लेख करते हुए लिखें।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          All my requirements for transferring money are completed and still I
          am unable to transfer the money. Why?
        </h4>
      ),
      desc: (
        <div>
          There might be some issue from the Bank or Paytm. Try transferring the
          money after 24 hours. If the problem still persists write to us at
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          mentioning your problem along with your Phone Number.
        </div>
      ),
    },
    mr: {
      title: (
        <h4>
          पैसे हस्तांतरित करण्यासाठी माझ्या सर्व आवश्यकता पूर्ण झाल्या आहेत आणि
          तरीही मी पैसे हस्तांतरित करू शकत नाही. का?
        </h4>
      ),
      desc: (
        <div>
          बँक किंवा पेटीएमकडून काही समस्या असू शकतात. 24 तासांनंतर पैसे
          हस्तांतरित करण्याचा प्रयत्न करा. तरीही समस्या कायम राहिल्यास तुमच्या
          फोन नंबरसह तुमच्या समस्येचा उल्लेख करून
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          वर आम्हाला लिहा.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>
          પૈસા ટ્રાન્સફર કરવા માટેની મારી તમામ જરૂરિયાતો પૂરી થઈ ગઈ છે અને તેમ
          છતાં હું પૈસા ટ્રાન્સફર કરવામાં અસમર્થ છું. શા માટે?
        </h4>
      ),
      desc: (
        <div>
          બેંક અથવા પેટીએમ તરફથી કોઈ સમસ્યા હોઈ શકે છે. 24 કલાક પછી પૈસા
          ટ્રાન્સફર કરવાનો પ્રયાસ કરો. જો સમસ્યા હજુ પણ યથાવત્ રહે તો
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          પર તમારા ફોન નંબર સાથે તમારી સમસ્યાનો ઉલ્લેખ કરીને અમને લખો.
        </div>
      ),
    },
  },
  {
    key: 'why-was-i-banned-from-transferring-money',
    hi: {
      title: (
        <h4>
          पैसे ट्रांसफर करने की मेरी सभी आवश्यकताएं पूरी हो चुकी हैं और फिर भी
          मैं पैसे ट्रांसफर करने में असमर्थ हूं। क्यों?
        </h4>
      ),
      desc: (
        <div>
          बैंक या पेटीएम से कुछ समस्या हो सकती है। 24 घंटे के बाद पैसे ट्रांसफर
          करने की कोशिश करें। अगर फिर भी समस्या बनी रहती है तो हमें{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          पर लिखें और अपने फोन नंबर के साथ अपनी समस्या का उल्लेख करें।
        </div>
      ),
    },
    en: {
      title: <h4>Why was I banned from transferring money?</h4>,
      desc: (
        <div>
          You get banned from transferring money from InCash because we might
          have detected some unusual activity on your InCash account. If you
          think that you were incorrectly banned, write us at{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          mentioning your problem along with your Phone Number.
        </div>
      ),
    },
    mr: {
      title: <h4>मला पैसे ट्रान्सफर करण्यास का बंदी घातली गेली?</h4>,
      desc: (
        <div>
          तुम्हाला InCash मधून पैसे हस्तांतरित करण्यावर बंदी घातली आहे कारण
          आम्हाला तुमच्या InCash खात्यावर काही असामान्य गतिविधी आढळून आल्या
          आहेत. तुमच्यावर चुकीच्या पद्धतीने बंदी घातली गेली आहे असे तुम्हाला
          वाटत असल्यास, आम्हाला{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          वर तुमच्या फोन नंबरसह तुमच्या समस्येचा उल्लेख करून लिहा.
        </div>
      ),
    },
    gu: {
      title: (
        <h4>શા માટે મને પૈસા ટ્રાન્સફર કરવા પર પ્રતિબંધ મૂકવામાં આવ્યો?</h4>
      ),
      desc: (
        <div>
          તમને InCash માંથી નાણાં ટ્રાન્સફર કરવા પર પ્રતિબંધ છે કારણ કે અમને
          તમારા InCash એકાઉન્ટ પર કેટલીક અસામાન્ય પ્રવૃત્તિ મળી હશે. જો તમને
          લાગે કે તમને ખોટી રીતે પ્રતિબંધિત કરવામાં આવ્યા છે, તો તમારા ફોન નંબર
          સાથે તમારી સમસ્યાનો ઉલ્લેખ કરીને અમને{' '}
          <a
            href="mailto:InCashappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            InCashappofficial@gmail.com
          </a>{' '}
          પર લખો.
        </div>
      ),
    },
  },
  {
    key: 'what-is-daily-checkin-how-can-i-avail-it',
    hi: {
      title: <h4>डेली चेकइन क्या है? मैं इसका लाभ कैसे उठा सकता हूं?</h4>,
      desc: (
        <div>
          डेली चेकइन उस उपयोगकर्ता के लिए एक इनाम प्रणाली है जो दैनिक आधार पर
          हमारे ऐप का उपयोग करता है। यह एक स्ट्रीक आधारित इनाम है जो स्ट्रीक की
          निरंतरता के साथ बढ़ता है। आप गिफ्ट आइकन पर क्लिक करके होम स्क्रीन से
          इसको अर्जित कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>What is Daily Checkin? How can I avail it?</h4>,
      desc: (
        <div>
          Daily Checkin is a reward system for the user who use our app on a
          daily basis. It is a streak based reward which increases with the
          continuation of streak. You can claim it from the home screen by
          clicking on the gift icon.
        </div>
      ),
    },
    mr: {
      title: <h4>दैनिक चेकइन म्हणजे काय? मी त्याचा लाभ कसा घेऊ शकतो?</h4>,
      desc: (
        <div>
          डेली चेकइन ही आमची अॅप रोज वापरणाऱ्या वापरकर्त्यांसाठी एक बक्षीस
          प्रणाली आहे. हे स्ट्रीकवर आधारित बक्षीस आहे जे स्ट्रीक चालू राहिल्याने
          वाढते. गिफ्ट आयकॉनवर क्लिक करून तुम्ही होम स्क्रीनवरून त्यावर दावा करू
          शकता
        </div>
      ),
    },
    gu: {
      title: <h4>દૈનિક ચેકઇન શું છે? હું તેનો કેવી રીતે લાભ લઈ શકું?</h4>,
      desc: (
        <div>
          દૈનિક ચેકઇન એ વપરાશકર્તા માટે એક પુરસ્કાર પ્રણાલી છે જેઓ અમારી
          એપ્લિકેશનનો દૈનિક ધોરણે ઉપયોગ કરે છે. તે સ્ટ્રીક આધારિત પુરસ્કાર છે જે
          સ્ટ્રીક ચાલુ રાખવા સાથે વધે છે. તમે ગિફ્ટ આઇકોન પર ક્લિક કરીને હોમ
          સ્ક્રીન પરથી તેનો દાવો કરી શકો છો
        </div>
      ),
    },
  },
];
